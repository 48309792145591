import { Color, ShaderMaterial, UniformsUtils, Vector2 } from 'three';

import glslLuma from '@resn/gozer-glsl/color/luma.glsl';
import glslDraw from '@resn/gozer-glsl/shapes/draw.glsl';
import glslRect from '@resn/gozer-glsl/shapes/rect.glsl';
import { materialEasyUniforms, simpleVs } from '@resn/gozer-three';

import glslSaturate from '@/libs/lygia//math/saturate.glsl';
import glslBrightnessContrast from '@/libs/lygia/color/brightnessContrast.glsl';
import glslGamma2Linear from '@/libs/lygia/color/space/gamma2linear.glsl';
import glslLinear2Gamma from '@/libs/lygia/color/space/linear2gamma.glsl';
import glslSnoise from '@/libs/lygia/generative/snoise.glsl';
import glslRatio from '@/libs/lygia/space/ratio.glsl';
import glslRotate from '@/libs/lygia/space/rotate.glsl';
import glslScale from '@/libs/lygia/space/scale.glsl';

class ForegroundMaterial extends ShaderMaterial {
    constructor(props) {
        props = Object.assign(
            {
                vertexShader,
                fragmentShader,
                name: 'ForegroundMaterial',
                defines: {
                    USE_MASK: true,
                    USE_DEBUG: false,
                },
            },
            props
        );
        super(props);

        const uniforms = {
            tMap: { value: null },
            tNoise: { value: null },

            u_palette: { value: new Array(3).fill(0).map((_) => new Color()) },
            u_color_offset: { value: 0 },
            u_brightness: { value: 0.2 },
            u_mask_radius: { value: 0.2 },
            u_time: { value: 0 },
            u_resolution: { value: new Vector2() },
        };
        this.uniforms = UniformsUtils.merge([uniforms, this.uniforms]);
        materialEasyUniforms(this, this.uniforms);
    }
}
export default ForegroundMaterial;

const vertexShader = simpleVs;
const fragmentShader = /* glsl */ `

varying vec2 vUv;

uniform sampler2D tMap;
uniform sampler2D tNoise;

uniform float u_color_offset;
uniform float u_brightness;
uniform float u_time;
uniform float u_mask_radius;
uniform vec2 u_resolution;

${glslDraw}
${glslRect}
${glslLuma}
${glslSaturate}
${glslGamma2Linear}
${glslLinear2Gamma}
${glslBrightnessContrast}
${glslRatio}
${glslSnoise}
${glslRotate}
${glslScale}

#define NUM_COLORS 3
uniform vec3 u_palette[NUM_COLORS];

vec3 colorRamp(in float t) {
    vec3 palette[NUM_COLORS] = u_palette;
    vec3 c = palette[0];
    for(int i = 0; i < NUM_COLORS - 1; ++ i) {
        float s = float(i);
        float d = 1.0 / float(NUM_COLORS - 1);
        c = mix(c, palette[i + 1], smoothstep(s * d, (s + 1.0) * d, 1.0 - t));
    }
    return c;
}

void main() {
    vec4 tDiffuse = texture2D(tMap, vUv);
    tDiffuse = saturate(tDiffuse);

    vec3 c = vec3(0.);
    #ifdef USE_BACKGROUND
        c = vec3(u_color_offset);
    #endif
    c = mix(tDiffuse.rgb, c, 1. - tDiffuse.a);
                    
    float a = tDiffuse.a;
    float l = luma(c);
    float t = u_time;
    float r = u_mask_radius;
    float b = u_brightness;
    
    vec2 st = vUv;

    #ifndef USE_DIFFUSE
        c = colorRamp(l);
    #endif
    c = brightnessContrast(c, b, 1.);
    #ifdef USE_MASK
        // st = ratio(st, u_resolution);

        vec2 stM = st;
        stM = scale(stM, 1. / r);
        stM += vec2(
            sin(t * 0.4) * 0.1,
            cos(t * 0.6) * -0.1
        );
        stM = rotate(stM, t * 0.5);

        vec2 stN0 = st / 2.;
        stN0 = rotate(stN0, t * -0.1);

        vec2 stN1 = st / 2.;
        stN1 = rotate(stN1, t * 0.3);
        
        float n0;
        float n1;
        #ifdef USE_NOISE_TEXTURES
            n0 = texture2D(tNoise, stN0).r;
            n1 = texture2D(tNoise, stN1).r;
        #else 
            n0 = snoise(stN0);
            n1 = snoise(stN1);
        #endif

        float d = 1. - fill(sdRect(stM, 1., 0.6), 0.7, 1.1);
        float m = n0 * n1;
        float msk = saturate(d + (d * m * 0.5));

        c *= msk;
    #endif

    gl_FragColor = vec4(c, 1.);
    gl_FragColor = gamma2linear(gl_FragColor);

    #if defined(USE_DEBUG) && defined(USE_MASK)
    gl_FragColor.rgb = vec3(tDiffuse.rgb);
    gl_FragColor.a = 1.;
    #endif
}`;
