export const themeParams = [
    {
        paletteIdx: 0,
        circScale: 1.4,
        circVel: 0.008,
        brightness: 0.34,
        cOffsetVel: 0.006,
        speed: 1,
        mask_radius: 1.5,
    },
    {
        paletteIdx: 1,
        circScale: 1.4,
        circVel: 0.01,
        brightness: 0.3,
        cOffsetVel: 0.01,
        speed: 1.3,
        mask_radius: 1.3,
    },
    {
        paletteIdx: 2,
        circScale: 1.3,
        circVel: 0.01,
        brightness: 0.45,
        cOffsetVel: 0.01,
        speed: 1.5,
        mask_radius: 1.4,
    },
    {
        paletteIdx: 3,
        circScale: 1.5,
        brightness: 0.32,
        cOffsetVel: 0.01,
        speed: 1.6,
        mask_radius: 1.34,
    },
    {
        paletteIdx: 4,
        circScale: 1.3,
        brightness: 0.3,
        cOffsetVel: 0.02,
        speed: 1.8,
        mask_radius: 1.34,
    },
];
