<template>
    <slot />
</template>

<script setup>
    import {
        AdditiveBlending,
        Color,
        InstancedBufferAttribute,
        InstancedBufferGeometry,
        InstancedMesh,
        Mesh,
        Object3D,
        PlaneGeometry,
        Scene,
        ShaderLib,
        ShaderMaterial,
        WebGLRenderTarget,
    } from 'three';
    import { randFloat, randFloatSpread } from 'three/src/math/MathUtils.js';
    import { computed, inject, onMounted, reactive, watch } from 'vue';

    import glslCircle from '@resn/gozer-glsl/shapes/circle.glsl';
    import glslDraw from '@resn/gozer-glsl/shapes/draw.glsl';
    import { urlSearchParams } from '@resn/gozer-misc';
    import { defaultHooks, materialEasyUniforms, modifySource } from '@resn/gozer-three';
    import { usePane, useRafBool, useViewportResize } from '@resn/gozer-vue';
    import gsap from '@resn/gsap';

    import Circle from './circle';
    import ForegroundMaterial from './material';
    import { themeParams } from './params';
    import { LAYER_FG, PALETTE_THEME } from '~/core/constants';
    import { useNoiseTextures } from '~/providers/NoiseTexturesProvider';

    const { renderer, orthoCamera, scene: sceneMain } = inject('renderer');

    const props = defineProps({
        visible: { type: Boolean, default: true },
        active: { type: Boolean, default: true },
        theme: { type: Number, default: 0 },
        dimmed: { type: Boolean, default: false },
    });

    const mode = urlSearchParams.get
        ? urlSearchParams.get('update')
            ? 'update'
            : 'default'
        : 'default';
    const active = computed(() => props.active);
    const visible = computed(() => props.visible);

    const scene = new Scene();
    const fbo = new WebGLRenderTarget(1, 1);

    const { generateNoise } = useNoiseTextures();

    let velOffset = 0;

    const propsMain = reactive({
        paletteIdx: 0,

        circRadius: { x: 0.5, y: 0.5 },
        circCenter: { x: 0, y: 0 },
        circScale: 1.6,
        circVel: 0.008,

        cOffset: 0,
        cOffsetVel: 0.006,
    });

    const propsTween = {
        paletteLrp: 0,
    };

    const propsUniforms = reactive({
        brightness: 0.2,
        speed: 1,
        mask_radius: 1.6,
    });

    const paletteA = new Array(3).fill(0).map((_) => new Color());
    const paletteB = new Array(3).fill(0).map((_) => new Color());

    const nCircles = 6;
    const shaderCircles = new ShaderMaterial({
        vertexShader: ShaderLib.basic.vertexShader,
        fragmentShader: /* glsl */ `
                varying vec2 vUv;
                varying float v_color_offset;
                #define PI 3.141592653589793
                ${glslDraw}
                ${glslCircle}
                void main() {
                    vec2 st = vUv - 0.5;
                    float sd;
                    sd = fill(sdCircle(st, vec2(0.)), 0.5, 0.5);
                    vec3 c = vec3(v_color_offset);
                    gl_FragColor = vec4(c, sd);
                }
            `,
        uniforms: {},
        depthWrite: false,
        depthTest: false,
        transparent: true,
        name: 'ForegroundCircles',
        defines: {
            USE_UV: true,
        },
    });
    materialEasyUniforms(shaderCircles);

    shaderCircles.onBeforeCompile = (shader) => {
        shader.vertexShader = modifySource(shader.vertexShader, defaultHooks.vertexHooks, {
            uniforms: /* glsl */ `
                attribute float a_color_offset;
                varying float v_color_offset;
            `,
            postTransform: /* glsl */ `
                v_color_offset = a_color_offset;
            `,
        });
    };

    const geo = new PlaneGeometry(1, 1);
    const geoInstanced = new InstancedBufferGeometry().copy(geo);
    geoInstanced.setAttribute(
        'a_color_offset',
        new InstancedBufferAttribute(new Float32Array(nCircles * 1), 1)
    );
    geoInstanced.instanceCount = nCircles;

    const meshInstance = new InstancedMesh(geoInstanced, shaderCircles, nCircles);
    meshInstance.layers.set(LAYER_FG);
    scene.add(meshInstance);

    const dummyCircles = new Object3D();

    let circles = Array.from(new Array(nCircles)).map((_, i) => {
        return new Circle({
            i,
            meshInstance,
            objectRef: dummyCircles,

            cOffsetInit: (i / nCircles) * Math.PI * 2,
            cOffsetVel: propsMain.cOffsetVel,
            cOffset: 0,

            circVel: propsMain.circVel,

            scVel: randFloat(0.004, 0.008),
            scInit: propsMain.circScale,
            scRand: randFloat(0, 0.1),

            angOffset: { x: randFloatSpread(Math.PI), y: randFloatSpread(Math.PI) },
            angVel: {
                x: propsMain.circVel * randFloat(0.5, 1),
                y: propsMain.circVel * randFloat(0.5, 1),
            },

            pPtCenter: propsMain.circCenter,
            pRad: propsMain.circRadius,
        });
    });

    const shader = new ForegroundMaterial({
        blending: AdditiveBlending,
    });
    shader.tMap = fbo.texture;
    onMounted(() => {
        shader.tNoise = generateNoise().value;
        shader.defines.USE_NOISE_TEXTURES = shader.tNoise !== null;
    });

    const mesh = new Mesh(new PlaneGeometry(1, 1), shader);
    mesh.layers.set(LAYER_FG);
    sceneMain.add(mesh);

    const setUniforms = (values) => {
        const { brightness, mask_radius } = values;
        shader.u_brightness = brightness;
        shader.u_mask_radius = mask_radius;
    };
    watch(propsUniforms, (uniforms) => setUniforms(uniforms), { immediate: true });

    const setTheme = (i) => {
        const duration = 1;
        const ease = 'sine.out';

        if (themeParams[i]) {
            const _ = themeParams[i];
            for (const key in _) {
                if (propsMain[key] !== undefined) {
                    if (key == 'paletteIdx' || mode == 'update') propsMain[key] = _[key];
                    else gsap.to(propsMain, { [key]: _[key], duration, ease });
                }
                if (propsUniforms[key] !== undefined) {
                    if (mode == 'update') propsUniforms[key] = _[key];
                    else gsap.to(propsUniforms, { [key]: _[key], duration, ease });
                }
            }
        }
    };
    watch(
        () => props.theme,
        (i) => setTheme(i),
        { immediate: true }
    );

    watch(
        propsMain,
        ({ cOffsetVel, circScale, circVel }) => {
            circles.forEach((c) => {
                c.props.cOffsetVel = cOffsetVel;
                c.props.scVel = circVel;
                c.props.scInit = circScale;
            });
        },
        { immediate: true }
    );

    const setPalette = ({ color0, color1, color2 }) => {
        paletteB[0].set(color0);
        paletteB[1].set(color1);
        paletteB[2].set(color2);
    };

    const setPaletteIndex = (i) => {
        if (~~i !== i) return;
        paletteA.map((c, i) => c.copy(paletteB[i]));

        propsTween.paletteLrp = 0;

        gsap.killTweensOf(propsTween, { paletteLrp: true });
        gsap.to(propsTween, { paletteLrp: 1, duration: 1, ease: 'sine.out' });
    };

    const setDimmed = (bool) => {
        console.log('🚀 ~ setDimmed ~ bool:', bool);
        if (bool) {
            gsap.killTweensOf(propsUniforms, { brightness: true, mask_radius: true });
            gsap.to(propsUniforms, {
                brightness: 0,
                mask_radius: 1.6,
                duration: 1,
                ease: 'power1.out',
            });
        } else setTheme(props.theme);
    };

    watch(
        () => propsMain.paletteIdx,
        (i) => {
            setPaletteIndex(i);
            setPalette({
                color0: PALETTE_THEME[~~i][0],
                color1: PALETTE_THEME[~~i][1],
                color2: PALETTE_THEME[~~i][2],
            });
        },
        { immediate: true }
    );

    watch(() => props.dimmed, setDimmed);

    const update = ({ delta: dt } = {}) => {
        const { cOffset, cOffsetVel } = propsMain;
        const { paletteLrp } = propsTween;
        const dt_s = dt / 1000;
        if (!velOffset) velOffset = 0;

        propsMain.cOffset = Math.sin((velOffset += cOffsetVel * (dt_s * 60))) * 0.5 + 0.5;

        for (let i = 0; i < paletteA.length; i++) {
            shader.u_palette[i].lerpColors(paletteA[i], paletteB[i], paletteLrp);
        }
        shader.u_color_offset = cOffset;
        shader.u_time += dt_s * propsUniforms.speed;
        for (let i = 0; i < circles.length; i++) circles[i].update({ delta: dt });

        meshInstance.instanceMatrix.needsUpdate = true;
        meshInstance.geometry.getAttribute('a_color_offset').needsUpdate = true;
    };

    const render = () => {
        renderer.setRenderTarget(fbo);
        renderer.clear();
        orthoCamera.layers.set(LAYER_FG);
        renderer.render(scene, orthoCamera);
        renderer.setRenderTarget(null);
    };
    useRafBool(active, ({ delta }) => {
        update({ delta });
        render();
    });

    watch(
        visible,
        (v) => {
            mesh.visible = v;
            meshInstance.visible = v;
        },
        { immediate: true }
    );

    defineExpose({ render });

    if (mode == 'update') {
        usePane([{ value: propsMain }, { value: propsUniforms, options: { min: 0.01 } }], {
            title: 'Foreground',
            expanded: true,
        });
    }

    useViewportResize(({ width, height }) => {
        const d = 0.5;
        fbo.setSize(width * d, height * d);
        mesh.scale.set(width, height, 1);
        circles.forEach((c) => c.setSize({ width, height }));
        shader.u_resolution.set(width, height);
    }, true);
</script>
