export default class Circle {
    constructor(props) {
        props = Object.assign(
            {
                i: 0,
                meshInstance: null,
                objectRef: null,

                cOffsetInit: 0,
                cOffsetVel: 0,
                cOffset: 0,

                scVel: 0,
                scInit: 1,
                scRand: 0,
                scSine: 0,
                s: 1,

                angVel: { x: 0, y: 0 },
                angOffset: { x: 0, y: 0 },
                angConst: { x: 0, y: 0 },

                pPtCenter: 0,
                pRad: 1,
            },
            props
        );

        this.props = props;

        this.object = props.objectRef;
        this.meshInstance = props.meshInstance;

        this.cOffset = 0;
        this.scOffset = 0;
        this.size = { width: 0, height: 0 };
        // if (props.addTo) props.addTo.add(mesh);
    }

    setSize({ width, height }) {
        this.size = { width, height };
    }

    update({ delta: dt } = {}) {
        const {
            pPtCenter,
            pRad,
            angVel,
            angConst,
            angOffset,
            scVel,
            scInit,
            scRand,
            scSine,
            cOffset,
            s,
            i,
        } = this.props;
        const { width, height } = this.size;
        const dt_s = (dt / 1000) * 60;

        this.cOffset += this.props.cOffsetVel * dt_s;
        this.scOffset += scVel;

        this.props.cOffset = Math.sin(this.props.cOffsetInit + this.cOffset) * 0.5 + 0.5;

        this.props.angConst.x += angVel.x * dt_s;
        this.props.angConst.y += angVel.y * dt_s;

        this.props.scSine = Math.sin(this.scOffset) * 0.2;
        this.props.s = (scInit + scRand + scSine) * Math.max(width, height);

        const ang = {
            x: angConst.x + angOffset.x,
            y: angConst.y + angOffset.y,
        };
        const x = pPtCenter.x + Math.cos(ang.x) * pRad.x;
        const y = pPtCenter.y + Math.sin(ang.y) * pRad.y;

        const pX = x * this.size.width;
        const pY = y * this.size.height;

        this.object.position.set(pX, pY, 0);
        this.object.scale.setScalar(s);
        this.object.updateMatrix();

        this.meshInstance.setMatrixAt(i, this.object.matrix);
        this.meshInstance.geometry.getAttribute('a_color_offset').setX(i, cOffset);
    }
}
